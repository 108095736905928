// @flow
import * as React from 'react';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import ArrowLeftIcon from '@material-ui/icons/ArrowBack';
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import FieldLink from '~plugins/prismic/components/FieldLink';
import StyledCard from '~components/StyledCard';
import WhitePaperCard from '~components/WhitePaperCard';

import getLinkedWhitePaperType from '../../helpers/getLinkedWhitePaperType';

import type {
  PrismicStructuredTextType,
  PrismicImageType,
  PrismicLinkType,
} from '~schema';

export type ClassKey =
  | 'carousel'
  | 'carouselWrapper'
  | 'carouselSlider'
  | 'topRightControls'
  | 'buttonBase'
  | 'leftArrow'
  | 'rightArrow'
  | 'itemGridItem'
  | 'itemCard'
  | 'itemCardImage'
  | 'cardTitle'
  | 'paginationDots';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  items: ?Array<?{
    item_title?: ?PrismicStructuredTextType,
    item_description?: ?PrismicStructuredTextType,
    item_image?: ?PrismicImageType,
    item_label?: ?PrismicStructuredTextType,
    item_link?: ?PrismicLinkType,
  }>,
  className?: string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  carousel: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  carouselSlider: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(-2),
    },
  },
  itemGridItem: {
    height: '100%',
    margin: theme.spacing(2),
    '& div.react-reveal': {
      height: '100%',
    },
  },
  itemCard: {
    width: '100%',
    height: '100%',
    '&:hover': {
      boxShadow:
        '0px 3px 4px -1px rgba(0,0,0,0.1), 0px 6px 8px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
    },
  },
  itemCardImage: {
    [theme.breakpoints.up('sm')]: {
      height: 220,
    },
    height: 160,
  },
  cardTitle: {
    '& > *': {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  topRightControls: {
    position: 'absolute',
    top: theme.spacing(-8),
    right: 0,
  },
  buttonBase: {
    width: 48,
    height: 48,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.grey[400],
    },
  },
  leftArrow: {},
  rightArrow: {},
  carouselWrapper: {
    overflow: 'visible !important',
  },
  paginationDots: {
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    '& .carousel__dot': {
      width: 18,
      height: 18,
      borderRadius: '50%',
      padding: 0,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'rgba(255,255,255,0.2)',
      margin: theme.spacing(1),
      '&:disabled': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
});
const RenderChildren = ({ children }: { children: React.Node }) => children;

const ContentCarousel = ({
  items,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const slidesToShow = (isXsDown && 1) || (isMdDown && 2) || 3;

  const renderTopRightControls = (
    <div className={classes.topRightControls}>
      <ButtonBack className={classes.buttonBase}>
        <ArrowLeftIcon className={classes.leftArrow} />
      </ButtonBack>
      <ButtonNext className={classes.buttonBase}>
        <ArrowRightIcon className={classes.rightArrow} />
      </ButtonNext>
    </div>
  );

  const cardClasses = React.useMemo(
    () => ({
      image: classes.itemCardImage,
    }),
    [classes],
  );

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(items, (item, index: number) =>
          item ? (
            <Slide
              index={index}
              key={index}
              aria-label={`slide-${index}`}
              className={classes.itemGridItem}
            >
              <Fade cascade duration={2000}>
                {getLinkedWhitePaperType(item?.item_link) ? (
                  <WhitePaperCard
                    title={item?.item_title}
                    description={item?.item_description}
                    image={item?.item_image}
                    link={item?.item_link}
                    label={item?.item_label}
                    whitePaper={getLinkedWhitePaperType(item?.item_link)}
                    className={classes.itemCard}
                    classes={cardClasses}
                  />
                ) : (
                  <StyledCard
                    title={item?.item_title}
                    description={item?.item_description}
                    image={item?.item_image}
                    link={item?.item_link}
                    label={item?.item_label}
                    cardActionAreaProps={{
                      component: FieldLink,
                      disableTouchRipple: true,
                      Fallback: RenderChildren,
                      field: item?.item_link,
                    }}
                    className={classes.itemCard}
                    classes={cardClasses}
                  />
                )}
              </Fade>
            </Slide>
          ) : null,
        ),
      ),
    [items, classes],
  );

  return (
    <CarouselProvider
      totalSlides={renderedItems.length}
      naturalSlideHeight={0}
      naturalSlideWidth={0}
      visibleSlides={slidesToShow}
      isIntrinsicHeight
      className={classnames(classes.carousel, className)}
      {...props}
    >
      <Slider
        aria-label="slider"
        className={classes.carouselWrapper}
        classNameTray={classes.carouselSlider}
      >
        {renderedItems}
      </Slider>
      {isXsDown ? <DotGroup className={classes.paginationDots} /> : null}
      {!isXsDown ? renderTopRightControls : null}
    </CarouselProvider>
  );
};

ContentCarousel.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'ContentCarousel' })(ContentCarousel);
