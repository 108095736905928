// @flow
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import getBackgroundPattern from '../helpers/getBackgroundPattern';
import defaultBackgroundPattern from '../helpers/defaultBackgroundPattern';
import type { Props, BackgroundPattern } from '../types';

export type Config = {|
  backgroundPattern?:
    | BackgroundPattern
    | ((Props<any>) => void | BackgroundPattern),
|};

export default function useBackgroundPatternFile(
  props: Config,
  dataprops: Props<any>,
) {
  const { backgroundPattern = defaultBackgroundPattern } = props;

  const {
    allFile: { nodes },
  } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            relativeDirectory: { eq: "patterns" }
          }
        ) {
          nodes {
            name
            childImageSharp {
              fluid(maxWidth: 2000, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `,
  );

  const prefix = process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE;

  return typeof backgroundPattern === 'function'
    ? [
        prefix &&
          getBackgroundPattern(
            nodes,
            ((...args) => {
              const pattern: BackgroundPattern | void = backgroundPattern(
                ...args,
              );
              return pattern ? `${prefix}-${pattern}` : undefined;
            })(dataprops),
          ),
        getBackgroundPattern(nodes, backgroundPattern(dataprops)),
      ].find(Boolean)
    : [
        prefix && getBackgroundPattern(nodes, `${prefix}-${backgroundPattern}`),
        getBackgroundPattern(nodes, backgroundPattern),
      ].find(Boolean);
}
