// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';
import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

import ContentCarousel from './ContentCarousel';
import RegularContentGrid from './RegularContentGrid';
import SliceTitle from '~components/SliceTitle';
import FieldLink from '~plugins/prismic/components/FieldLink';

const RelatedContentSlice = ({
  data,
  className,
  component,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceTitle
        title={data?.primary?.slice_title}
        description={data?.primary?.slice_description}
        className={classes.sliceTitle}
        idGeneration="title"
      />
      {data.items && data.items.length > 3 ? (
        <ContentCarousel items={data.items} />
      ) : (
        <RegularContentGrid items={data.items} />
      )}
      {data.primary?.slice_link ? (
        <Button
          component={FieldLink}
          field={data.primary?.slice_link}
          variant="text"
          color="primary"
          size="large"
          endIcon={<ArrowForward color="primary" />}
          disableTouchRipple
          aria-label={data.primary?.slice_link_label?.text}
          className={classes.archiveLink}
        >
          {data.primary?.slice_link_label?.text}
        </Button>
      ) : null}
    </Component>
  );
};

RelatedContentSlice.defaultProps = {
  className: undefined,
};

export const StyledRelatedContentSlice = withStyles<*, *, Props>(styles, { name: 'RelatedContentSlice' })(
RelatedContentSlice,
);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledRelatedContentSlice>,
>()(StyledRelatedContentSlice);
