// @flow
import * as sliceBackgroundColor from '../config/sliceBackgroundColor';
import type { Props, BackgroundColor } from '../types';

const backgroundColorDict = ({
  [sliceBackgroundColor.Primary]: 'primary',
  [sliceBackgroundColor.Secondary]: 'secondary',
  [sliceBackgroundColor.Grey]: 'grey',
  [sliceBackgroundColor.White]: 'white',
  [sliceBackgroundColor.Black]: 'black',
}: $ObjMap<typeof sliceBackgroundColor, <V>() => BackgroundColor>);

const defaultBackgroundColor = <E: {}>({
  data,
}: Props<E>): void | BackgroundColor =>
  (data.primary &&
    data.primary.slice_background_color &&
    backgroundColorDict[data.primary.slice_background_color]) ||
  undefined;

export default defaultBackgroundColor;
