// @flow
import * as sliceBackgroundPattern from '../config/sliceBackgroundPattern';
import type { Props, BackgroundPattern } from '../types';

const backgroundPatternDict = ({
  [sliceBackgroundPattern.Pattern1]: 'Pattern1',
  [sliceBackgroundPattern.Pattern2]: 'Pattern2',
  [sliceBackgroundPattern.Pattern3]: 'Pattern3',
}: $ObjMap<typeof sliceBackgroundPattern, <V>() => BackgroundPattern>);

const defaultBackgroundColor = <E: {}>({
  data,
}: Props<E>): void | BackgroundPattern =>
  (data.primary &&
    data.primary.slice_background_color &&
    backgroundPatternDict[data.primary.slice_background_color]) ||
  undefined;

export default defaultBackgroundColor;
