// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  sliceTitle: {},
  archiveLink: {
    marginTop: theme.spacing(6),
  },
});

export default styles;
