// @flow
import * as React from 'react';

import type { File } from '~schema';

export default function getBackgroundPattern(
  files: ?Array<File>,
  prismicPattern?: ?string,
): ?File {
  const pattern = files
    ? files.find(file => file.name === prismicPattern) || undefined
    : undefined;

  return pattern;
}
