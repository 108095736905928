// @flow
/* eslint-disable no-use-before-define */
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import compose from 'recompose/compose';
import BackgroundImage from 'gatsby-background-image';
import classnames from 'classnames';
import flow from 'lodash/fp/flow';

import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props, WithBodySectionSlicePatternHOC, Config } from './types';
import useBackgroundPatternFile from './hooks/useBackgroundPatternFile';
import {
  extractHtmlCss,
  sanitizeHtmlClass,
  sanitizeHtmlCss,
  sanitizeHtmlId,
} from './helpers/html';

const styles = unusedTheme => ({
  pattern: {
    '&::before': {
      opacity: '0.1 !important',
    },
    '&::after': {
      opacity: '0.1 !important',
    },
  },
});

const useStyles = makeStyles(styles);

export default function withBodySectionSlicePattern<Enhanced: {}>(
  config?: Config<Enhanced>,
): WithBodySectionSlicePatternHOC<Enhanced> {
  const { backgroundPattern, backgroundColor, ...withBodySectionConfig } =
    config || {};

  return compose(
    Component => {
      const WithBodySectionSlicePattern = (props: Props<Enhanced>) => {
        const { data, ...withBodySectionProps } = props;
        const classes = useStyles({ backgroundPattern });
        const pattern = useBackgroundPatternFile({ backgroundPattern }, props);

        const [Wrapper, WrapperProps] = React.useMemo(
          () =>
            pattern && pattern.childImageSharp
              ? [
                  BackgroundImage,
                  { className: classes.pattern, ...pattern.childImageSharp },
                ]
              : [React.Fragment, {}],
          [pattern],
        );

        const isPattern = pattern
          ? { slice_background_image: undefined }
          : null;

        const { htmlProps, css } = useSliceHtmlConfig(props);

        return (
          <Wrapper {...WrapperProps}>
            {css && (
              <style data-slice data-for={htmlProps.id}>
                {css}
              </style>
            )}
            <Component
              {...withBodySectionProps}
              {...htmlProps}
              data={React.useMemo(
                () => ({
                  ...data,
                  primary: {
                    ...(data?.primary || undefined),
                    isPattern,
                  },
                }),
                [data],
              )}
            />
          </Wrapper>
        );
      };
      return WithBodySectionSlicePattern;
    },
    withBodySectionSlice({
      ...withBodySectionConfig,
    }),
  );
}

function useSliceHtmlConfig({ data, className }) {
  const { html_id = data?.id, html_class, html_css } = data?.primary || {};

  const stableId = useStableId();

  const htmlProps = {
    className: classnames(
      className,
      html_class && sanitizeHtmlClass(html_class),
    ),
    id: (html_id && sanitizeHtmlId(html_id)) || stableId,
  };
  return {
    htmlProps,
    css: flow(
      extractHtmlCss,
      css => css && sanitizeHtmlCss(css, htmlProps),
    )(html_css),
  };
}

function useStableId() {
  const [stableId] = React.useState<string>(() => {
    try {
      // $FlowFixMe --> Reason: Crypto is not defined in flowtypes
      return new Crypto().randomUUID();
    } catch {
      return Math.random()
        .toString(36)
        .substring(2);
    }
  });
  return stableId;
}
