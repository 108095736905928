/* eslint-disable no-use-before-define */
// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Container, { type ContainerProps } from '@material-ui/core/Container';
import classnames from 'classnames';

import RichText, {
  hasRichTextFieldValue,
} from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import generateTitleId from '~helpers/generateTitleId';
import type { PrismicStructuredTextType, PrismicImageType } from '~schema';

export type ClassKey =
  | 'root'
  | 'titleWrapper'
  | 'image'
  | 'title'
  | 'description';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  id?: string,
  idGeneration?: 'title',
  titleImage?: ?PrismicImageType,
  title?: ?PrismicStructuredTextType,
  description?: ?PrismicStructuredTextType,
  contentAlign?: 'left' | 'centered',
  containerWidth?: $ElementType<ContainerProps, 'maxWidth'>,
  disablePaddingBottom?: boolean,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

const defaultAlignment = 'centered';

export const styles: Styles = theme => ({
  root: ({ contentAlign = defaultAlignment, disablePaddingBottom }) => ({
    width: '100%',
    paddingBottom: disablePaddingBottom ? 0 : theme.spacing(7),
    ...(contentAlign === 'left'
      ? {
          marginLeft: 0,
        }
      : null),
    marginTop: theme.spacing(-10),
    paddingTop: theme.spacing(10),
  }),
  titleWrapper: {},
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: 175,
  },
  title: {
    '* + &': {
      marginTop: theme.spacing(2),
    },
    '& > *': {
      color: theme.palette.common.black,
    },
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiListText-padding': {
      paddingTop: 16,
      paddingBottom: 16,
    },
    '& li:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
});

const SliceTitle = ({
  id,
  idGeneration,
  title,
  titleImage,
  description,
  contentAlign,
  containerWidth,
  disablePaddingBottom,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return hasRichTextFieldValue(title) || hasRichTextFieldValue(description) ? (
    <Container
      disableGutters
      maxWidth={containerWidth || "sm"}
      className={classnames(classes.root, className)}
      {...props}
      id={useTitleId({ title: title?.text, id, idGeneration })}
    >
      {titleImage || hasRichTextFieldValue(title) ? (
        <div className={classes.titleWrapper}>
          {titleImage ? (
            <NodeImage data={titleImage} className={classes.image} />
          ) : null}
          <RichText {...title} className={classes.title} />
        </div>
      ) : null}
      <RichText {...description} className={classes.description} />
    </Container>
  ) : null;
};

SliceTitle.defaultProps = {
  className: undefined,
  id: undefined,
  idGeneration: undefined,
  title: undefined,
  titleImage: undefined,
  description: undefined,
  contentAlign: undefined,
  containerWidth: undefined,
  disablePaddingBottom: undefined,
};

export default withStyles<*, *, *>(styles, { name: 'SliceTitle' })(SliceTitle);

export function useTitleId({
  title,
  id,
  idGeneration,
}: {
  title: ?string,
  id: $ElementType<Props, 'id'>,
  idGeneration: $ElementType<Props, 'idGeneration'>,
}) {
  return React.useMemo(
    () =>
      id ||
      (idGeneration &&
        {
          title: generateTitleId(title),
        }[idGeneration]) ||
      undefined,
    [title, id, idGeneration],
  );
}
