// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';

import BodySection from '~plugins/material-ui/components/BodySection';
import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import ContactForm from '~components/ContactForm';
import useContactForm from '~components/ContactForm/useContactForm';
import useContactFormValidationSchema from '~components/ContactForm/useContactFormValidationSchema';
import getContactFormTrackingProps from '~helpers/getContactFormTrackingProps';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'itemGridContainer'
  | 'itemGridItem'
  | 'textWrapper'
  | 'title'
  | 'description'
  | 'form'
  | 'backgroundImage'
  | 'image';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  currentPage?: ?string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    position: 'relative',
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  },
  wrapper: {},
  itemGridContainer: {},
  itemGridItem: {
    color: theme.palette.common.white,
    zIndex: 1,
  },
  textWrapper: {
    textAlign: 'left',
  },
  title: {},
  description: {
    '* + &': {
      marginTop: theme.spacing(2),
    },
  },
  form: {
    marginTop: theme.spacing(6),
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 0,
    right: 0,
    mixBlendMode: 'multiply',
    opacity: 0.8,
    pointerEvents: 'none',
  },
  image: {
    width: '60%',
    height: '100%',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& img': {
      objectFit: 'contain !important',
    },
  },
});

const FooterContactForm = ({
  className,
  classes,
  currentPage,
  ...props
}: Props): React.Node => {
  const contactForm = useContactForm();

  const inputSector = currentPage ? currentPage.toUpperCase() : undefined;

  const contactFormTrackingProps =
    inputSector &&
    getContactFormTrackingProps({
      inputSector,
      appendInputSectorToContentSource: true,
    });

  const contactFormProps = {
    validationSchema: useContactFormValidationSchema(contactForm),
    classes: React.useMemo(
      () => ({
        root: classes.form,
      }),
      [classes],
    ),
  };

  return !contactFormTrackingProps ? null : (
    <div className={classnames(classes.root, className)} {...props}>
      <div className={classes.backgroundImage}>
        <NodeImage
          data={contactForm?.data?.background_image}
          className={classes.image}
        />
      </div>
      <BodySection className={classes.wrapper}>
        <Grid container className={classes.itemGridContainer}>
          <Grid item xs={12} md={6} className={classes.itemGridItem}>
            <div className={classes.textWrapper}>
              <RichText
                {...contactForm?.data?.title}
                className={classes.title}
              />
              <RichText
                {...contactForm?.data?.description}
                className={classes.description}
              />
            </div>
            <ContactForm
              node={contactForm}
              listKey={undefined}
              textFieldColor="light"
              buttonColor="secondary"
              {...contactFormTrackingProps}
              {...contactFormProps}
            />
          </Grid>
        </Grid>
      </BodySection>
    </div>
  );
};

FooterContactForm.defaultProps = {
  className: undefined,
  currentPage: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'FooterContactForm' })(FooterContactForm);
