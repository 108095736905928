// @flow
import * as React from 'react';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import FieldLink from '~plugins/prismic/components/FieldLink';
import getLinkedWhitePaperType from '../../helpers/getLinkedWhitePaperType';
import StyledCard from '~components/StyledCard';
import WhitePaperCard from '~components/WhitePaperCard';
import type {
  PrismicStructuredTextType,
  PrismicImageType,
  PrismicLinkType,
} from '~schema';

export type ClassKey = 'itemGridContainer' | 'itemGridItem' | 'itemCard';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  items: ?Array<?{
    item_title?: ?PrismicStructuredTextType,
    item_description?: ?PrismicStructuredTextType,
    item_image?: ?PrismicImageType,
    item_label?: ?PrismicStructuredTextType,
    item_link?: ?PrismicLinkType,
  }>,
  className?: string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  itemGridContainer: {},
  itemGridItem: {
    '& div.react-reveal': {
      height: '100%',
    },
  },
  itemCard: {
    width: '100%',
    height: '100%',
    '&:hover': {
      boxShadow:
        '0px 3px 4px -1px rgba(0,0,0,0.1), 0px 6px 8px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
    },
  },
});

const RenderChildren = ({ children }) => children;

const RegularContentGrid = ({
  items,
  className,
  component,
  classes,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const renderedItems = React.useMemo(
    () =>
      compact(
        map(items, (item, index: number) =>
          item ? (
            <Grid
              item
              key={index}
              xs={12}
              md={4}
              className={classes.itemGridItem}
            >
              <Fade cascade duration={2000}>
                {getLinkedWhitePaperType(item?.item_link) ? (
                  <WhitePaperCard
                    title={item?.item_title}
                    description={item?.item_description}
                    image={item?.item_image}
                    link={item?.item_link}
                    label={item?.item_label}
                    whitePaper={getLinkedWhitePaperType(item?.item_link)}
                    className={classes.itemCard}
                  />
                ) : (
                  <StyledCard
                    title={item?.item_title}
                    description={item?.item_description}
                    image={item?.item_image}
                    link={item?.item_link}
                    label={item?.item_label}
                    className={classes.itemCard}
                    cardActionAreaProps={{
                      component: FieldLink,
                      disableTouchRipple: true,
                      field: item?.item_link,
                      Fallback: RenderChildren,
                    }}
                  />
                )}
              </Fade>
            </Grid>
          ) : null,
        ),
      ),
    [items],
  );

  return (
    <Grid
      container
      spacing={isSmUp ? 4 : 5}
      alignItems="stretch"
      className={classnames(classes.itemGridContainer, className)}
      {...props}
    >
      {renderedItems}
    </Grid>
  );
};

RegularContentGrid.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'RegularContentGrid' })(RegularContentGrid);
